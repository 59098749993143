<template>
  <div class="index-content">
    <!-- <img style="width: 100%;display: block;" src="@images/2banner.png"> -->
    <Carousel></Carousel>
    <div class="content">
        <div
            class="img"
            v-for="item in 4"
            :key="item"
            :style="getStyle(item)"
        >
            <img :src="require(`./images/icon_content_index_${item}.png`)" >
        </div>
    </div>
    <!-- <div class="we-can-provide">
      <div class="we-can-provide-title">我们能提供</div>
      <div class="we-can-provide-text">硬件、软件、资源、教研、服务、管理六位一体的智慧教育整体解决方案</div>
      <div class="we-can-provide-content">
        <div class="we-can-provide-content-text-left">
          <div class="we-can-provide-content-text-item" style="color: #027E84">科学智能管理</div>
          <div class="we-can-provide-content-text-item" style="color: #006BC9">多方位服务支持</div>
          <div class="we-can-provide-content-text-item" style="color: #00A2DE">课题引领教研</div>
        </div>
        <img class="we-can-provide-content-img" src="@images/we_can_provide.png">
        <div class="we-can-provide-content-text-right">
          <div class="we-can-provide-content-text-item" style="color: #00A2DE">教育专属硬件</div>
          <div class="we-can-provide-content-text-item" style="color: #027E84">智慧教学软件</div>
          <div class="we-can-provide-content-text-item" style="color: #006BC9">优质教学资源</div>
        </div>
      </div>
    </div>
    <div class="our-value">
      <div class="our-value-title">我们的价值</div>
      <div class="our-value-text">打造互联互通互动的高品质生态学习环境</div>
      <div class="our-value-content">
        <div class="our-value-content-img img1"></div>
        <div class="our-value-content-img img2"></div>
        <div class="our-value-content-img img3"></div>
        <div class="our-value-content-img img4"></div>
      </div>
    </div>
    <div class="our-serve">
      <div class="our-serve-title">我们的服务</div>
      <div class="our-serve-text">更好的服务，铸就更好的信任</div>
      <div class="our-serve-text-bottom">项目覆盖全国31个省（自治区、直辖市），服务数千所学校，百万用户信赖之选</div>
      <div class="our-serve-content">
        <img class="our-serve-content-map" src="@images/our_serve_map.png">
        <div class="our-serve-content-list">
          <div class="our-serve-content-list-item">驻校服务:功能指导、问题解决等</div>
          <div class="our-serve-content-list-item">教研服务:教研顾问、教研培训等</div>
          <div class="our-serve-content-list-item">活动支持:备课指导、课程打磨等</div>
          <div class="our-serve-content-list-item">家长服务:家长培训、家访指导等</div>
          <div class="our-serve-content-list-item">技术保障:7*24小时运维保障</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Carousel from '@components/carousel/carousel.vue';
export default {
    name: 'index',
    components: {
        Carousel,
    },
    data () {
        return {

        };
    },
    mounted () {

    },
    methods: {
        getStyle (index) {
            let imgUrl = require(`./images/icon_bg_index_${index}.png`);
            return { 'background-image': 'url(' + imgUrl + ')' };
        },
    },
    destroyed () {

    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
  @import "@style/variable.scss";
  .index-content {
    width: 100%;
  }
.content {
    .img {
        text-align: center;
        padding: 60px 0;
        background-repeat: no-repeat;
        background-size: cover;
        img {
            max-width: 1024px;
        }
    }
}
  .we-can-provide {
    width: 100%;
    height: 796px;
    padding-top: 73px;
  }

  .we-can-provide-title {
    font-size:40px;
    font-weight:bold;
    line-height:40px;
    margin: 0 auto 37px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .we-can-provide-text {
    font-size:18px;
    font-weight:bold;
    line-height:18px;
    margin: 0 auto 39px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .we-can-provide-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .we-can-provide-content-text-left {
    margin-right: 45px;
  }

  .we-can-provide-content-text-right {
    margin-left: 60px;
  }

  .we-can-provide-content-text-item {
    font-size:25px;
    font-weight:400;
    line-height:25px;
    margin-bottom: 95px;
  }

  .we-can-provide-content-text-item:last-child {
    margin: 0;
  }

  .we-can-provide-content-img {
    width: 567px;
    height: 528px;
    display: block;
  }

  .our-value {
    width: 100%;
    height: 796px;
    padding-top: 73px;
    background: linear-gradient(0deg,rgba(232,240,247,0.7),rgba(244,248,252,0.7));
  }

  .our-value-title {
    font-size:40px;
    font-weight:bold;
    line-height:40px;
    margin: 0 auto 37px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .our-value-text {
    font-size:18px;
    font-weight:bold;
    line-height:18px;
    margin: 0 auto 76px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .our-value-content {
    display: flex;
    justify-content: center;
  }

  .our-value-content-img {
    width: 219px;
    height: 419px;
    border-radius: 10px;
    box-shadow: 1px 4px 11px 3px #CCD2FD;
    margin-right: 38px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .our-value-content-img:last-child {
    margin: 0;
  }

  .img1 {
    background-image: url('~@images/our_value1.png'), url('~@images/our_value_hover1.png');
  }

  .img2 {
    background-image: url(~@images/our_value2.png), url('~@images/our_value_hover2.png');
  }

  .img3 {
    background-image: url(~@images/our_value3.png), url('~@images/our_value_hover3.png');
  }

  .img4 {
    background-image: url(~@images/our_value4.png), url('~@images/our_value_hover4.png');
  }

  .img1:hover {
    background-image: url(~@images/our_value_hover1.png);
  }

  .img2:hover {
    background-image: url(~@images/our_value_hover2.png);
  }

  .img3:hover {
    background-image: url(~@images/our_value_hover3.png);
  }

  .img4:hover {
    background-image: url(~@images/our_value_hover4.png);
  }

  .our-serve {
    width: 100%;
    height: 796px;
    padding-top: 73px;
  }

  .our-serve-title {
    font-size:40px;
    font-weight:bold;
    line-height:40px;
    margin: 0 auto 50px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .our-serve-text {
    font-size:22px;
    line-height:22px;
    margin: 0 auto 30px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .our-serve-text-bottom {
    font-size:18px;
    line-height:18px;
    margin: 0 auto 68px;
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
  }

  .our-serve-content {
    display: flex;
    justify-content: center;
  }

  .our-serve-content-map {
    width: 611px;
    height: 428px;
    display: block;
  }

  .our-serve-content-list {
    margin-left: 70px;
    margin-top: 30px;
  }

  .our-serve-content-list-item {
    width: 346px;
    height: 74px;
    background-image: url(~@images/our_serve_list.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-size:20px;
    padding: 20px 0 0 33px;
  }
</style>
