<template>
  <div class="carousel-box" ref="carouselBox">
    <el-carousel
      :interval="5000"
      height="500px"
    >
      <el-carousel-item
        class="banber banner-one"
        :class="{'banner-two': item.id === 2, 'banner-three': item.id === 3,'banner-four': item.id === 4, 'banner-five': item.id === 5}"
        v-for="item of bgImages"
        :key="item.id"
      >
        <div class="banner-content">
          <div>
            <p
              :class="{'content-one': index === 0, 'content-two': index !== 0}"
              v-for="(text, index) of item.content"
              :key="index"
            >{{ text }}</p>
            <div>
              <img
                class="banner-four-img"
                :src="option.src"
                v-for="(option, optionIndex) of item.images"
                :key="optionIndex"
              >
            </div>
            <!-- <div
              class="circle circle-right"
              v-if="item.id === 3"
            >
              <div class="circle-item"></div>
            </div>
            <div
              class="circle circle-left"
              v-if="item.id === 3"
            >
              <div class="circle-item"></div>
            </div> -->
          </div>
          <img
              class="banner-two-img"
              :class="{'screen-img': !isSmallScreen}"
              :src="bannerTwoImg"
              v-if="item.id === 2"
            >
          <!-- <img
            :src="bannerThreeImg"
            v-if="item.id === 3"
          > -->
          <img
            :src="bannerFourImg"
            :class="{'screen-img': !isSmallScreen}"
            v-if="item.id === 4"
          >
          <img
            :src="bannerFiveImg"
            v-if="item.id === 5"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
    name: 'carousel',
    data () {
        return {
            bgImages: [
                // {
                //     id: 1,
                //     content: [],
                // },
                {
                    id: 2,
                    content: [
                        'AI赋能教育  数据引领智慧教学',
                        '以“优学AI”智能引擎为核心，依托人工智能、',
                        '大数据等先进技术，促进教育个性化、科学化、智能化发展',
                    ],
                },
                // {
                //     id: 3,
                //     content: [
                //         '教育专属智能终端',
                //         '智慧教学的好助手，',
                //         '辅助教师高效授课，满足学生个性化学习',
                //     ],
                // },
                {
                    id: 4,
                    content: [
                        '教育专属智能终端',
                        '辅助教师高效授课，满足学生个性化学习',
                    ],
                    images: [],
                },
                // {
                //     id: 5,
                //     content: [
                //         '智慧教育解决方案',
                //         '覆盖硬件、软件、平台、资源等产业链',
                //         '提供教、学、管、研、培等多方位服务',
                //     ],
                // },
            ],
            bannerTwoImg: require('@images/carousel_two.png'),
            bannerThreeImg: require('@images/carousel_three.png'),
            bannerFourImg: require('@images/carousel_four_icon.png'),
            bannerFiveImg: require('@images/carousel_five_bg.png'),
            isSmallScreen: false, // 小屏
            onScroll: this.$debounce(() => {
                this.resetBannerStyle();
            }, 100),
        };
    },
    mounted: function () {
        this.resetBannerStyle();
        window.addEventListener('resize', this.onScroll, false);
        window.addEventListener('scroll', this.onScroll, false);
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.onScroll);
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        resetBannerStyle () {
            this.$nextTick(() => {
                let elmentVM = this.$refs.carouselBox;
                if (elmentVM) {
                    let { width } = elmentVM.getBoundingClientRect();
                    if (width < 1600) {
                        this.isSmallScreen = true;
                    } else {
                        this.isSmallScreen = false;
                    }
                }
            });
        },
        $debounce (fn, wait, outset = false, forwards = false) {
            let context = null;
            let lastCall = null;
            let argu = null;
            let timeOut = null;
            let isFix = outset === 'fixed';

            /* 延迟检测函数，检测距离最后一次触发的时间间隔 */
            function timeUp () {
                let interval = new Date() - lastCall; // 最后一次触发的时间间隔;
                if (interval < wait) {
                    timeOut = setTimeout(timeUp, isFix && wait || wait - interval);
                    /* fixed模式的固定时间点执行 */
                    isFix && fn.apply(context, argu);
                } else {
                    timeOut = null;
                    if (!outset || (isFix && forwards)) { // 结束时间点执行和fixed模式的最后一次触发时间点执行
                        fn.apply(context, argu);
                    }
                    argu = context = null;
                }
            }

            /* 返回闭包函数 */
            return function (...rest) {
                context = this;
                argu = rest;
                /* 记录最后触发时间 */
                if (!isFix || forwards || !lastCall) {
                    lastCall = new Date();
                }
                if (outset && !timeOut) { // 开始时间点执行和fixed模式的固定时间点执行
                    fn.apply(context, argu);
                }
                if (!timeOut) timeOut = setTimeout(timeUp, wait); // 首次触发产生延迟函数
            };
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "@style/variable.scss";
  .carousel-box {
      .banner {
        .banner-content {
            height: 100%;
        }
      }
      .banner-one {
        background-image: url('../../assets/images/carousel_one.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .banner-content {
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          color: #fff;
          &>div {
                padding-right: 500px;
            }
          p {
            margin: 0;
          }
          .content-one {
            font-size: 40px;
            padding-bottom: 49px;
          }
          .content-two {
            font-size: 22px;
            padding-bottom: 16px;
          }
        }
      }
      .banner-two {
        background-image: linear-gradient(to right, #020133, #1c1a67);
        .banner-content {
            display: flex;
            justify-content: center;
            &>div {
                padding-right: 0 !important;
                // min-width: 542px;
            }
            .content-one {
                padding-bottom: 36px;
            }
            &>img {
                position: relative;
                // left: 100px;
            }
            .screen-img{
                margin-left: 100px;
            }
        }
      }
      .banner-three {
        background-image: url('../../assets/images/carousel_three.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .banner-content {
            position: relative;
            // &>div {
            //     padding-right: 156px;
            // }
            .circle {
                position: absolute;
                opacity: 0.3;
                width: 250px;
                height: 250px;
                border-radius: 50%;
                mask: radial-gradient(transparent 100px, #000 100px);
                overflow: hidden;
                .circle-item {
                    height: 100%;
                    width: 100%;
                    float: right;
                }
            }
            .circle-left {
                left: 30px;
                bottom: -115px;
                .circle-item {
                    background: linear-gradient(to bottom, #053ff2, #0544f2);
                }
            }
            .circle-right {
                right: 20px;
                bottom: -70px;
                transform: rotate(180deg);
                .circle-item {
                    background: linear-gradient(to bottom, #075bf4, #0a80f5);
                }
            }
            .content-one {
                padding-bottom: 36px;
            }
        }
      }
      .banner-four {
        background-image: url('../../assets/images/carousel_four.png');
        .banner-content {
            display: flex;
            justify-content: center;
            &>div {
                    padding-right: 0;
                }
            .content-one {
                padding-bottom: 28px;
            }
            .content-two {
                padding-bottom: 50px;
            }
            .banner-four-img {
                display: inline-block;
            }
            .screen-img{
                margin-left: 200px;
            }
        }
      }
      .banner-five {
        background-image: url('../../assets/images/carousel_five.png');
        .banner-content {
            &>div {
                padding-right: 100px;
                min-width: 500px;
            }
            .content-one {
                padding-bottom: 52px;
            }
            &>img {
                position: relative;
                left: 100px;
            }
        }
      }
  }
</style>
